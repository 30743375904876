
@media (max-width: 576px) {
    .navbar {
      background-color: #fff !important;
    }
    
.font-big{
    font-size: 50px;
  }
  }
