@font-face {
  font-family: 'nunito-regular';
  src: url('../../public/Font/Nunito-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'nunito-Bold';
  src: url('../../public/Font/Nunito-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../../public/Font/Montserrat-Regular.ttf') format('truetype');
}

body{
  font-family: Montserrat-Regular;
}

h1,h2,h3,h4,h5,h6{
  font-family: nunito-bold;
}

.front-img{
	height: 100vh;
	width: 100vw;
object-fit: cover;
position: absolute;
top: 0;
z-index: -1;
}

.color-white{
  color: white !important;
}

.color-black{
color: black;
}

.color-yellow{
  color: #f05936 !important;
}

.background-yellow{
  background-color: #f05936;
}

.background-light-black{
background-color: #222222;
}

.Ucase{
text-transform: uppercase;
}

.header-image {
  position: relative;
  width: 100%;
  height: 122vh; /* Adjust according to your needs */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-text {
  position: absolute;
  color: #f05936; /* Text color for visibility */
  z-index: 2;
  font-size: 20px;
  left: 5.7%;
  text-shadow: rgba(6, 0, 0, 0.774) 5px 3px;
}

.fade-text-container {
  position: relative;
  width: 300px; /* Adjust to your text width */
  height: 50px; /* Adjust to your text height */
  overflow: hidden; /* Prevent overflow during the animation */
}

.fade-up-down {
  position: absolute;
  top: -81px;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateY(20px); /* Initial position shifted down */
  animation: fadeInUpDown 4s infinite; /* Total duration */
}

.first-text {
  animation-delay: 0s;
}

.second-text {
  animation-delay: 2.25s; /* Starts halfway through the animation */
}

@keyframes fadeInUpDown {
  0%, 22%, 100% {
    opacity: 0;
    transform: translateY(20px); /* Start and end shifted down */
  }
  22%, 55% {
    opacity: 1;
    transform: translateY(0); /* Fade in and move up */
  }
  55%, 77% {
    opacity: 1;
    transform: translateY(0); /* Stay visible for 1.5 seconds */
  }
  77%, 100% {
    opacity: 0;
    transform: translateY(-20px); /* Fade out and move up */
  }
}



.header-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
}

.front-text {
  position: absolute;
  color: black; /* Text color for visibility */
  z-index: 2;
  margin-bottom: 30px;
  font-size: 20px;
  left: 6%;
  margin-top: -160px;
  text-shadow: rgb(209, 110, 110) 2px 0px;
}

.header-image-other {
  position: relative;
  width: 100%;
  height: 50vh; /* Set this height according to your needs */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}


.front-text-other {
  position: absolute;
  color: black; /* Text color for visibility */
  padding-top: 10%;
  z-index: 2;
  margin-bottom: 100px;
  font-size: 20px;
}

.text-shadow{
  text-shadow: rgb(245, 240, 240) 2px 1px;
 
}


.why-choose-block{
  background-image:url(../../public/images/front-page/bd.jpg);
  background-size: cover;
  color: black;
}




.job {
  display: inline-block;
  overflow: hidden;
}

.holder-animation {
  animation: holder 4s; /* Ensure this matches the animation duration */
}

@keyframes holder {
  0% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Base navbar styles */
.navbar {
  transition: background-color 0.3s ease;
}

.navbar-light {
  background-color: transparent; /* Default color when at the top */
}

.navbar-scrolled {
  background-color: white; /* Background color when scrolled */
  opacity: 0.9;
  
}

/* Optional: Style the links or other elements when the navbar is scrolled */
.navbar-scrolled .nav-link {
  color: black; /* Change link color when scrolled */
}


.primary-button{
  padding: 8px 30px;
  background-color: #f05936;
  border: 2px solid black;
  color: black;
  text-transform: uppercase;
}

.primary-button:hover{
background-color: black;
color:white;
}

.secondry-button{
  padding: 5px 15px;
  background-color: #f05936;
  border: 1px solid black;
  color: black;
}

.secondry-button:hover{
  background-color: black;
color:white;
}

.carousel-control-next-icon {
  background-image: url(../../public/images/icon/arrow-right.png) !important;
  cursor: pointer;
}

.carousel-control-prev-icon {
  background-image: url(../../public/images/icon/arrow-left.png) !important;
  cursor: pointer;
}

.carousel-indicators .active {
  background-color: #f05936 !important;
}

.carousel-indicators li {
 cursor: pointer;
  width: 13px;
  height: 13px;
  background-color: rgba(43, 44, 35, 0.5);
}





/* Active link should be bold */
.navbar-nav .nav-link.active {
  font-weight: bold;
  border-bottom: 2px solid black;
  

}

.breadcrumb{
  background-color: transparent !important; 
  font-size: 13px;
  justify-content: center;
  
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.4rem ;
  padding-left: 0.2rem; 
  color: black;
  content: ">";
}

.breadcrumb-item.active {
  color:black!important;
}

.font-big{
  font-size: 80px;
  font-family: nunito-Bold;
}

.navbar-scrolled .navbar-toggler-icon {
  background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e);
}


/* Add this CSS to your stylesheet */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(360px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fade-in-up {
  opacity: 0; /* Ensures content is invisible initially */
  animation: fadeInUp 2s ease-in-out forwards;
}

.fade-in-up.delay-1 {
  animation-delay: 0.3s;
}

.fade-in-up.delay-2 {
  animation-delay: 0.6s;
}

.fade-in-up.delay-3 {
  animation-delay: 0.9s;
}

@keyframes slideInLeft {
  0% {
      transform: translateX(-200%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

.slide-in-left {
  opacity: 0; /* Start hidden */
  transform: translateX(-100%); /* Start off-screen */
  transition: opacity 0.5s ease-in-out; /* Smooth transition */
}

.slide-in-left.animate {
  animation: slideInLeft 1s ease-in-out forwards;
  opacity: 1.5; /* Ensure visibility after animation */
}



.fade-up-down {
  position: absolute;
  top: -81px;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateY(20px); /* Initial position shifted down */
  animation: fadeInUpDown 4s infinite; /* Total duration */
}

.first-text {
  animation-delay: 0s;
}

.second-text {
  animation-delay: 2s; /* Starts halfway through the animation */
}

@keyframes fadeInUpDown {
  0%, 22%, 100% {
      opacity: 0;
      transform: translateY(20px); /* Start and end shifted down */
  }
  22%, 55% {
      opacity: 1;
      transform: translateY(0); /* Fade in and move up */
  }
  55%, 77% {
      opacity: 1;
      transform: translateY(0); /* Stay visible for 1.5 seconds */
  }
  77%, 100% {
      opacity: 0;
      transform: translateY(-20px); /* Fade out and move up */
  }
}
